import { Page } from '../../components/Page';
import { layout } from '../../styles/layout';
import { LoadingPage } from '../LoadingPage';
import { NotFoundPage } from '../NotFoundPage';
import { Stream } from '../../lib/graphql.document';
import { Loader } from '../../components/Loader';
import { PostBlocks } from '../../components/PostBlocks';
import { useMediaBlocks } from '../../react-hooks/useMediaPosts';
import { useFetchWhenInView } from '../../react-hooks/useFetchWhenInView';
import { CreatePostButton } from '../../components/CreatePostButton';
import { ButtonVariant } from '../../components/Button';
import { tw } from '../../styles/tw';
import { ROUTES } from '../../lib/ROUTES';

const BlogsPage = (): JSX.Element => {
  const { isDataReceived, onFetchMore, mediaBlocks, hasNextPage } = useMediaBlocks({ stream: Stream.Blog });
  const { intersectionObserverRef } = useFetchWhenInView(onFetchMore);

  if (mediaBlocks) {
    return (
      <Page
        className={tw(layout, 'mb-5 flex flex-col items-stretch justify-start')}
        documentTitle="Блоги"
        documentDescription={PUBLIC_CONFIG.BLOGS_DESCRIPTION}
        overflowY="scroll"
        canonicalUrl={ROUTES.BLOGS.$buildPath({})}
      >
        <CreatePostButton className="mt-3.5 md:hidden" variant={ButtonVariant.Outlined} buttonAlwaysVisible />
        <PostBlocks blocks={mediaBlocks} />
        {hasNextPage && <Loader className="mx-auto my-5" ref={intersectionObserverRef} />}
      </Page>
    );
  }

  if (isDataReceived) {
    return <NotFoundPage />;
  }

  return <LoadingPage />;
};

export { BlogsPage };
export default BlogsPage;
